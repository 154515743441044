<template>
  <div>
    <b-form
      @submit.prevent="onSubmit"
    >
      <b-row>
        <b-col md="9">
          <b-form-file
            v-model="docsToUpload"
            :placeholder="$t('Choose a file or drop it here')"
            multiple
          />
        </b-col>
        <!-- Action Buttons -->
        <b-col md="3">
          <b-button
            variant="primary"
            class="mb-sm-0 mr-0 mr-sm-1"
            block
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              small
              type="grow"
            />
            {{ this.$t('upload') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <vue-perfect-scrollbar
      ref="activity"
      :settings="perfectScrollbarSettings"
      class="scroll-area"
    >
      <b-container fluid>
        <transition-group
          tag="div"
          class="row"
          enter-active-class="animated fadeInLeft"
          leave-active-class="animated fadeOutLeft"
        >
          <b-col
            v-for="document in userDocumentsData"
            :key="document.id"
            cols="12"
            sm="6"
            md="6"
            class="mt-1"
          >
            <div>
              <feather-icon
                icon="XCircleIcon"
                class="cart-item-remove cursor-pointer"
                @click.stop="onDeleteDocument(document.id)"
              />
              <small>
                <b-link
                  :href="document.doc"
                  target="_blank"
                >
                  {{ document.original_name }}
                </b-link>
                <br />
                <span class="ml-1">{{ document.uploaded_at }}</span>
              </small>
            </div>
          </b-col>
        </transition-group>
      </b-container>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BLink, BFormFile, BCol, BRow, BButton, BForm, BSpinner, BContainer,
} from 'bootstrap-vue'
import { ref, onMounted, onBeforeMount } from "@vue/composition-api"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from "vue-toastification/composition"
import useUserView from "@/views/apps/user/users-view/useUserView"
import i18n from "@/libs/i18n"

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormFile,
    BButton,
    BForm,
    BSpinner,
    BContainer,
    VuePerfectScrollbar,
  },
  setup() {
    const loading = ref(false)
    const toast = useToast()

    const docsToUpload = ref(null)

    const {
      userData, fetchUserDocuments, uploadDocuments, deleteDocument, userDocumentsData,
    } = useUserView()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
      wheelPropagation: false,
    }

    const onDeleteDocument = async documentId => {
      loading.value = true

      await deleteDocument({ uuid: userData.value.uuid, documentId })
          .then(() => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('message.document deleted'),
                variant: 'success',
              },
            })
          })
          .catch(error => {
          })

      loading.value = false
    }

    const onSubmit = async () => {
      loading.value = true

      await uploadDocuments({ uuid: userData.value.uuid, documents: docsToUpload.value })
          .then(() => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('message.documents uploaded'),
                variant: 'success',
              },
            })
          })
          .catch(error => {
          })

      loading.value = false
    }

    onMounted(async () => {
      await fetchUserDocuments({ uuid: userData.value.uuid })
    })

    return {
      onDeleteDocument,
      loading,
      onSubmit,
      docsToUpload,
      userDocumentsData,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss">
.scroll-area {
  position: relative;
  margin: auto;
  height: 300px;
}
</style>
