<template>
  <b-card>
   <b-tabs>
      <b-tab
        v-if="userJobData.projects"
        :title="$t('users.projects_and_tasks')"
        :active="true"
      >
        <div
          v-for="project in userJobData.projects"
          :key="project.id"
        >
          <user-job-project :project="project" />
        </div>
      </b-tab>

      <b-tab
        v-if="userJobData.maintenances"
        :title="$t('maintenances.title')"
      >
        <div
          v-for="maintenance in userJobData.maintenances"
          :key="maintenance.id"
        >
          <user-job-maintenance :maintenance="maintenance" />
        </div>
      </b-tab>

      <b-tab
        v-if="userJobData.courses"
        :title="$t('users.courses_risks')"
      >
        <b-card-text>
          Chocolate croissant cupcake croissant jelly donut. Cheesecake toffee apple pie chocolate bar biscuit tart croissant. Lemon drops danish cookie. Oat cake macaroon icing tart lollipop cookie sweet bear claw.
        </b-card-text>
        <b-card-text>
          Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies dragée. Chocolate bar liquorice cheesecake cookie chupa chups marshmallow oat cake biscuit. Dessert toffee fruitcake ice cream powder tootsie roll cake.
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard, BCardText,
} from 'bootstrap-vue'
import { onBeforeMount } from '@vue/composition-api'
import useUserView from './useUserView'
import UserJobProject from './user-job-content/UserJobProject.vue'
import UserJobMaintenance from './user-job-content/UserJobMaintenance.vue'

export default {
  components: {
    BTabs,
    BCardText,
    BTab,
    BCard,
    UserJobProject,
    UserJobMaintenance,
  },
  setup() {
    const { userJobData, fetchUserProjects, fetchUserMaintenances } = useUserView()

    onBeforeMount(async () => {
      fetchUserProjects()
      fetchUserMaintenances()
    })

    return { userJobData }
  },
}
</script>

<style scoped>

</style>
