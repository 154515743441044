<template>
  <b-card>
    <b-tabs>
      <b-tab
        :title="$t('activity')"
      >
        <user-view-user-activity />
      </b-tab>
      <!--
      <b-tab
        :title="$t('photos')"
      >
        <user-view-user-images />
      </b-tab>
      -->
      <b-tab
        :title="$t('Documentation')"
      >
        <user-view-user-docs />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { onBeforeMount } from '@vue/composition-api'
import useUserView from './useUserView'
import UserViewUserActivity from './user-additional-info/UserViewUserActivity.vue'
import UserViewUserDocs from './user-additional-info/UserViewUserDocs.vue'

export default {
  components: {
    UserViewUserActivity,
    UserViewUserDocs,
    BTabs,
    BTab,
    BCard,
  },
  setup() {
    const {
      fetchUserActivity,
      userActivityData,
      fetchUserDocuments,
      userDocumentsData,
    } = useUserView()

    onBeforeMount(async () => {
      fetchUserActivity()
      fetchUserDocuments()
    })

    return {
      userActivityData,
      userDocumentsData,
    }
  },
}
</script>

<style scoped>

</style>
