<template>
  <b-overlay
    :show="loading"
    variant="transparent"
    opacity="1"
    blur="5px"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-form
      @submit.prevent="onSubmit"
    >
      <div v-if="userPersonalData">
        <b-card>
          <b-card-text>
            <div class="row">
              <div class="col-6">
                <h6>
                  {{ userPersonalData.name + ' ' + (userPersonalData.surname ? userPersonalData.surname : '') }}
                </h6>
              </div>
              <div
                v-if="$can('create', 'users') && userPersonalData.role !== 'Super-admin'"
                class="col-6"
              >
                <b-form-select
                  v-model="userPersonalData.role"
                  :options="availableRoles"
                  size="sm"
                  class="mb-1"
                />
              </div>
            </div>
          </b-card-text>

          <div
            v-if="userPersonalData.role !== 'Super-admin'"
            class="row"
          >
            <div class="col-8">
              <p>{{ $t("status.account_status") }}: {{ $t("status." + userPersonalData.status) }}</p>
            </div>

            <div
              class="col-3"
              v-if="$can('delete_all', 'users')"
            >
              <b-button
                block
                size="sm"
                v-b-tooltip.hover.v-danger
                v-b-modal.modal-delete-user
                :title="$t('users.Delete')"
                variant="outline-danger"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </div>

          <b-row>
            <b-col
              cols="12"
              class="mb-2 text-center"
            >
              <b-avatar
                icon="people-fill"
                class="badge-minimal"
                :src="userPersonalData.avatar"
                size="90px"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mb-2 text-center"
            />
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                :label="this.$t('users.email')"
                label-for="email"
              >
                <b-input-group
                  v-b-modal.modal-change-email
                  :append="$t('change')"
                  class="cursor-pointer"
                >
                  <b-form-input
                    id="email"
                    v-model="userPersonalData.email"
                    type="email"
                    readonly
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Field: Username -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="this.$t('users.name')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="userPersonalData.name"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="this.$t('users.surname')"
                label-for="surname"
              >
                <b-form-input
                  id="surname"
                  v-model="userPersonalData.surname"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="this.$t('users.telephone')"
                label-for="telephone"
              >
                <b-form-input
                  id="telephone"
                  v-model="userPersonalData.telephone"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="this.$t('users.legal_id')"
                label-for="legal_id"
              >
                <b-form-input
                  id="legal_id"
                  v-model="userPersonalData.legal_id"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                :label="this.$t('users.address')"
                label-for="address1"
              >
                <b-form-input
                  id="address1"
                  v-model="userPersonalData.address1"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label=""
                label-for="address2"
              >
                <b-form-input
                  id="address2"
                  v-model="userPersonalData.address2"
                />
              </b-form-group></b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="this.$t('users.city')"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="userPersonalData.city"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="this.$t('users.state')"
                label-for="state"
              >
                <b-form-input
                  id="state"
                  v-model="userPersonalData.state"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="this.$t('users.postal_code')"
                label-for="postal_code"
              >
                <b-form-input
                  id="postal_code"
                  v-model="userPersonalData.postal_code"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 btn-block"
                type="submit"
              >
                {{ this.$t('save_changes') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-form>

    <b-modal
      id="modal-change-email"
      :title="$t('users.change_email')"
      @ok="changeEmail"
    >
      <b-form ref="form">
        <p>{{ $t("users.email") }}</p>
        <p>{{ userPersonalData.email }}</p>
        <b-form-group
          :label="$t('users.new_email')"
          label-for="email"
          type="email"
        >
          <b-form-input
            id="email"
            v-model="newEmail"
            placeholder="email@email.com"
          />
        </b-form-group>
        <b-form-group
          :label="$t('users.repeat_email')"
          label-for="repeat_email"
        >
          <b-form-input
            id="repeat_email"
            v-model="newEmailRepeated"
            type="email"
            placeholder="email@email.com"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-delete-user"
      :title="$t('users.Delete')"
      @ok="deleteActualUser"
      ok-variant="danger"
      :ok-title="$t('users.yes_delete')"
    >
      <p>{{ this.$t("users.confirm_delete") }}</p>
    </b-modal>
  </b-overlay>
</template>

<script>

import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormSelect,
  BFormGroup, BFormInput, BForm, BTable,
  BInputGroup, BInputGroupPrepend, BInputGroupAppend,
  BCard, BCardHeader, BCardTitle, BCardText, BFormCheckbox, BBadge,
  BOverlay, BModal, VBModal, VBTooltip,
} from 'bootstrap-vue'
import vSelect from "vue-select"
import { ref, onBeforeMount, watch } from "@vue/composition-api"
import { useToast } from 'vue-toastification/composition'
import i18n from '@/libs/i18n'
import useUserView from './useUserView'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay,
    vSelect,
    BModal,
    VBModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const loading = ref(true)
    const {
      userPersonalData, fetchUser, updateUser, fetchRoles, deleteUser, availableRoles, newEmail, setNewEmail,
    } = useUserView()
    const toast = useToast()
    const newEmailRepeated = ref()

    onBeforeMount(async () => {
      await fetchUser()
      await fetchRoles()

      if (!userPersonalData.value) {
        // return back
      }
    })

    const changeEmail = bvModalEvt => {
      if (!newEmail.value || newEmail.value === '' || newEmail.value !== newEmailRepeated.value) {
        bvModalEvt.preventDefault()
        toast.error(i18n.t('users.email_repeated_not_match'))
        return
      }

      setNewEmail()
      bvModalEvt.close()
    }

    const deleteActualUser = () => {
      deleteUser()
    }

    watch(userPersonalData, value => {
      if (value) {
        loading.value = false
      } else {
        loading.value = true
      }
    })

    const onSubmit = async () => {
      await updateUser()

      if (userPersonalData.value === undefined) {
        toast.error(i18n.t("an_error_occur"))
      } else {
        toast.success(i18n.t("changes_saved"))
      }
    }

    return {
      userPersonalData,
      changeEmail,
      onSubmit,
      deleteActualUser,
      loading,
      availableRoles,
      newEmail,
      newEmailRepeated,
    }
  },
}

</script>

<style>

</style>
