<template>
  <b-card
    no-body
    class="card-employee-task"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        {{ project.name }}
      </h5>

      <b-badge
        pill
        :variant="'light-' + project.status"
      >
        {{ $t("projects.progress") }}
      </b-badge>
    </b-card-header>

    <b-card-body>
      <b-list-group
        v-if="project.tasks"
      >
        <b-list-group-item
          v-for="task in project.tasks"
          :key="task.id"
          class="d-flex justify-content-between align-items-center"
        >
          <span>{{ task.name }}</span>
          {{ task.progress }}
        </b-list-group-item>
      </b-list-group>

    </b-card-body>
  </b-card>
</template>

<script>
import {
 BBadge, BCardHeader, BListGroup, BListGroupItem, BCard, BCardBody,
} from 'bootstrap-vue'

export default {
  name: "UserJobProject",
  components: {
    BBadge,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BCard,
    BCardBody,
  },
  props: {
    project: null,
  },
  setup() {

  },
}
</script>

<style scoped>

</style>
