<template>
  <div>
    <template>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="5"
          lg="5"
          md="5"
        >
          <user-view-user-personal-info-card />
        </b-col>

        <b-col
          cols="12"
          md="7"
          xl="7"
          lg="7"
        >
          <b-row class="match-height">
            <b-col
              cols="12"
            >
              <user-view-user-job-card />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
            >
              <user-view-user-additional-info-card />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import {
  onUnmounted,
} from "@vue/composition-api"
import {
  BRow, BCol,
} from 'bootstrap-vue'
import userStoreModule from '../userStoreModule'
import UserViewUserPersonalInfoCard from './UserViewUserPersonalInfoCard.vue'
import UserViewUserJobCard from './UserViewUserJobCard.vue'
import UserViewUserAdditionalInfoCard from './UserViewAdditionalInfoCard.vue'
// import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,

    // Local Components
    UserViewUserPersonalInfoCard,
    UserViewUserJobCard,
    UserViewUserAdditionalInfoCard,
    // UserViewUserPermissionsCard,
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'user'

    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, userStoreModule)

    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) {
        store.commit(`${TODO_APP_STORE_MODULE_NAME}/refreshAll`, null)
        store.unregisterModule((TODO_APP_STORE_MODULE_NAME))
      }
    })
  },
}
</script>

<style>

</style>
