export default {
  namespaced: true,
  state: {
    data: null,
    activity: null,
    photos: null,
    documents: null,
  },
  getters: {
    getData(state) {
      return state.data
    },
    getActivity(state) {
      return state.activity
    },
    getPhotos(state) {
      return state.photos
    },
    getDocuments(state) {
      return state.documents
    },
  },
  mutations: {
    refreshState(state, data) {
      state.data = data
      state.activity = data
      state.maintenances = data
      state.photos = data
      state.documents = data
    },
    setData(state, data) {
      state.data = data
    },
    setActivity(state, data) {
      state.activity = data
    },
    setPhotos(state, data) {
      state.photos = data
    },
    setDocuments(state, data) {
      state.documents = data
    },
  },
  actions: {
    refreshAll({ commit }) {
      commit('setData', { data: null })
      commit('setActivity', { data: null })
      commit('setPhotos', { data: null })
      commit('setDocuments', { data: null })
    },
  },
}
