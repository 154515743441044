import {
  ref, reactive, computed,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'
import { useToast } from "vue-toastification/composition"

export default function useUsersList() {
  const resolveUserProjectStatus = status => {
    if (status === 'danger') return 'Terminado'
    if (status === 'success') return 'En proceso'

    return 'No especificado'
  }

  const userPersonalData = ref()
  const availableRoles = ref([])
  const userJobData = reactive({
    projects: null,
    maintenances: null,
  })

  const roles = ref([])

  const userActivityData = ref(null)
  const newEmail = ref(null)
  const toast = useToast()

  const userData = computed(() => store.state.user.data)
  const fetchUser = async () => {
    await store.dispatch('model/user/fetchUser', { uuid: router.currentRoute.params.uuid })
        .then(response => {
          userPersonalData.value = response.data
          store.commit('user/setData', response.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            userPersonalData.value = undefined
          }
        })
  }

  const deleteUser = async () => {
      await store.dispatch('model/user/deleteUser', { uuid: router.currentRoute.params.uuid })
      .then(response => {
        if (response.status === 204) {
          // Ir al listado de usuarios
            router.push("/users/list")
        }
      })
      .catch(error => {
        alert("Error users.error_deleting")
      })
  }

  const updateUser = async () => {
    await store.dispatch('model/user/updateUser', userPersonalData.value)
    .then(response => {
      userPersonalData.value = response.data
    })
    .catch(error => {
      if (error.response.status === 404) {
        userPersonalData.value = undefined
      }
    })
  }

  const fetchUserProjects = async () => {
    await store.dispatch('model/user/fetchUserProjects', { uuid: router.currentRoute.params.uuid })
        .then(response => {
          userJobData.projects = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userJobData.projects = undefined
          }
        })
  }

  const setNewEmail = async () => {
    await store.dispatch('model/user/setNewEmail', { id: router.currentRoute.params.id, newEmail: newEmail.value })
        .then(response => {
          if (response.status === 200) {
            userPersonalData.value.email = newEmail.value
          }
        })
        .catch(error => {
          if (error.response.status === 400) {
            toast.error(i18n.t("users.error_updating_email"))
          }
        })
  }

  const fetchUserMaintenances = async () => {
    await store.dispatch('model/user/fetchUserMaintenances', { uuid: router.currentRoute.params.uuid })
        .then(response => {
          userJobData.maintenances = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userJobData.maintenances = undefined
          }
        })
  }

  const fetchUserActivity = async () => {
    await store.dispatch('model/user/fetchUserActivity', { uuid: router.currentRoute.params.uuid })
    .then(response => {
        userActivityData.value = response.data
    })
    .catch(error => {
        if (error.status === 404) {
            userActivityData.value = undefined
        }
    })
  }

  const fetchRoles = async () => {
    await store.dispatch('model/user/fetchRoles')
        .then(response => {
          // availableRoles.value = response.data
          // availableRoles.value.forEach(rol => roles.value.push({ value: rol, text: i18n.t(`roles.${rol}`) }))
          response.data.forEach(rol => availableRoles.value.push({ value: rol, text: i18n.t(`roles.${rol}`) }))
        })
        .catch(error => {
          if (error.response.status === 404) {
            availableRoles.value = undefined
          }
        })
  }

  const userDocumentsData = computed(() => store.state.user.documents)

  const fetchUserDocuments = async () => {
    await store.dispatch('model/user/fetchUserDocuments', { uuid: router.currentRoute.params.uuid })
      .then(response => {
        store.commit('user/setDocuments', response.data)
      })
      .catch(error => {
        if (error.status === 404) {
          store.commit('user/setDocuments', null)
        }
      })
  }

  const uploadDocuments = async params => {
    await store.dispatch('model/user/uploadDocuments', params)
      .then(response => {
        fetchUserDocuments(params)
        fetchUserActivity(params)
      })
      .catch(error => {
        if (error.response.status === 404) {
          //
        }
      })
  }

  const deleteDocument = async params => {
    await store.dispatch('model/user/deleteDocument', params)
      .then(response => {
        fetchUserDocuments(params)
        fetchUserActivity(params)
      })
      .catch(error => {
        if (error.response.status === 404) {
          //
        }
      })
  }

  return {
    fetchUser,
    fetchUserProjects,
    fetchUserMaintenances,
    fetchUserActivity,
    fetchRoles,
    deleteUser,
    updateUser,
    userData,
    userPersonalData,
    userJobData,
    userActivityData,
    resolveUserProjectStatus,
    availableRoles,
    newEmail,
    setNewEmail,
    roles,
    userDocumentsData,
    fetchUserDocuments,
    uploadDocuments,
    deleteDocument,
  }
}
